import React from "react"
import { Container, Row, Col, Button, Accordion, Card } from "react-bootstrap"
export default function Secfaq() {
  return (
    <>
      <Container fluid className="__bg-secondary-sl">
        <Container>
          <Row>
            <Col lg={12}>
              <div className="__wrappe-headline-sl">
                <h2 className="text-center mb-5">F A Q</h2>
              </div>
              <Accordion defaultActiveKey="0">
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="0">
                      <p className="text-dark __text-center-faq">
                        1. Maksudnya membership VIP ALL Access apa ya?
                        {/* {" "}
                        Apakah teknik di Attraction Mastery bisa dipraktekkan
                        oleh wanita introvert seperti saya?{" "} */}
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="0">
                    <Card.Body>
                      <p>
                        Membership VIP adalah kategori membership di platform
                        Trulav.id dimana kamu bisa mengakses semua kategori
                        course mulai dari FREE, Premium dan VIP. <br />
                        <br /> Hingga saat ini ada lebih dari 100+ video course
                        yang tersedia di Trulav.id <br />
                        <br />
                        Dengan join VIP membership, kamu bisa mengakses ratusan
                        video course dengan harga yang jauh lebih murah daripada
                        membeli satuan. Plus keuntungan fasilitas lainnya
                        seperti support system dan bisa ikutan Exclusive Webinar
                        GRATIS tanpa perlu biaya tambahan.
                        {/* Uniknya, Jose Aditya sendiri adalah seorang introvert.
                        Dan Jose percaya tidak ada yang bisa menghalangi seorang
                        introvert menjadi seorang yang mudah disukai dan dekat
                        selain diri kita sendiri yang membatasinya. */}
                      </p>
                      {/* <p>
                        Dengan teknik yang kamu dapatkan di Attraction Mastery,
                        kamu tidak lagi kebingungan menanam benih cinta di otak
                        pria yang kamu suka tanpa perlu nembak dan gak perlu
                        takut ditolak :)
                      </p> */}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="1">
                      <p className="text-dark __text-center-faq">
                        2. Apa yang terjadi setelah pembayaran saya berhasil?
                        {/* {" "}
                        Apakah ada jaminan pria yang saya suka akan menyukai
                        saya balik berkat teknik di Attraction Mastery?{" "} */}
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="1">
                    <Card.Body>
                      <p>
                        Kamu akan menerima email berupa akses ke akun membership
                        Trulav milikmu. Jika kamu sudah punya akun Trulav
                        sebelumnya, kamu bisa langsung login untuk mengakses
                        video course yang kamu inginkan.
                        {/* Ada dua hal yang bisa kita pilih dan menentukan
                        keberhasilan kita. Fokus pada hal yang bisa kita
                        kendalikan atau fokus pada hal yang tidak bisa kita
                        kendalikan.{" "} */}
                      </p>
                      {/* <p>
                        Mempraktekkan teknik-teknik attraction yang terukur
                        adalah hal yang bisa kamu kendalikan. Sedangkan
                        keputusan apakah dia memilih menjatuhkan hati padamu
                        adalah hal yang tidak bisa kamu kendalikan.
                      </p>
                      <p>
                        Fokus pada apa yang bisa kita kendalikan akan membuat
                        dirimu jauh lebih tenang, happy dan gak terjebak needy.{" "}
                        <br />
                        And THAT’S THE KEY!
                      </p> */}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="2">
                      <p className="text-dark __text-center-faq">
                        3. Bagaimana jika membership saya habis?
                        {/* {" "}
                        Bagaimana jika saya belum punya kandidat atau gebetan?{" "} */}
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="2">
                    <Card.Body>
                      <p>
                        Setelah durasi membershipmu habis, kamu bisa lakukan
                        perpanjangan dengan harga normal.
                        {/* Kira-kira mana kondisi yang lebih kamu sukai? Belum ada
                        gebetan tapi sudah tau ilmunya jadi bisa praktek
                        kapanpun kamu mau, atau.. nunggu ditikung dan gebetanmu
                        terlanjur memilih wanita lain karena kamu dianggap
                        kurang asyik dan menarik? */}
                      </p>
                      {/* <p>
                        {" "}
                        Jadilah menarik tanpa perlu menunggu ada yang bisa kamu
                        gebet atau nggak.. :)
                      </p> */}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="3">
                      <p className="text-dark __text-center-faq">
                        4. Kapan promo THR ini berakhir?
                        {/* {" "}
                        Apakah Attraction Mastery bisa saya gunakan untuk
                        menghangatkan kembali hubungan yang pernah renggang?{" "} */}
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="3">
                    <Card.Body>
                      <p>
                        Promo ini berakhir tanggal 26 April pukul 20:00 WIB
                        {/* ​Ada kesalahan yang membuat sebuah hubungan menjadi
                        renggang dan mempelajari Attraction Mastery bisa
                        membuatmu terhindar dan menghangatkan kembali hubungan
                        tersebut. */}
                      </p>
                      {/* <p>
                        Apalagi jika, dia masih terlihat membuka kesempatan.. ^
                        ^
                      </p> */}
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="4">
                      <p className="text-dark __text-center-faq">
                        5. Bagaimana jika saya sudah memiliki membership
                        sebelumnya?
                        {/* {" "}
                        Jika saya berhalangan hadir, apakah ada rekaman
                        trainingnya?{" "} */}
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="4">
                    <Card.Body>
                      <p>
                        Membership kamu akan terupdate sesuai durasi membership
                        yang kamu pilih
                        {/* ​​Jika kamu memilih join kelas VIP kamu akan mendapat
                        fasilitas audio rekaman training. Sehingga kamu bisa
                        mendengarkan ulang materinya */}
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="5">
                      <p className="text-dark __text-center-faq">
                        6. Apakah ada nomor WhatsApp yang bisa saya hubungi?
                        {/* {" "}
                        Apakah online trainingnya bisa diakses via HP?{" "} */}
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="5">
                    <Card.Body>
                      <p>
                        Kamu bisa hubungi tim kami via WhatsApp 0812-1913-5268
                        (Fakhri) Atau email di support@trulav.id
                        {/* ​​​Tentu saja, namun dari pengalaman yang ada mengikuti
                        online training via laptop/PC memberikan kenyamanan
                        lebih dan minim kendala dibandingkan mengakses via
                        handphone. */}
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                {/* <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="6">
                      <p className="text-dark __text-center-faq">
                        Mengapa saya perlu segera daftar sekarang?{" "}
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="6">
                    <Card.Body>
                      <p>
                        ​​​​Dalam skala 1-10, level kelangkaan training ini
                        berada di level 9.
                      </p>
                      <p>
                        Selain harus menunggu 1 tahun lagi untuk batch
                        berikutnya, pendaftaran online training ini ditutup
                        sewaktu-waktu tanpa pemberitahuan sebelumnya.
                      </p>
                      <p>
                        Mendengar fakta ini, kami yakin, kamu tidak perlu
                        ditakut-takuti untuk segera daftar sekarang.
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="7">
                      <p className="text-dark __text-center-faq">
                        Wah jadi gak sabar mau join Attraction Mastery!{" "}
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="7">
                    <Card.Body>
                      <p>
                        ​Yes, saran kami.. Segera putuskan daftar sebelum waktu
                        habis (Karena Cuma 4 Hari) dan kamu menyesal perlu
                        menunggu lebih lama untuk batch berikutnya
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card>
                <Card>
                  <Card.Header>
                    <Accordion.Toggle as={Button} variant="link" eventKey="8">
                      <p className="text-dark __text-center-faq">
                        Masih ada pertanyaan?{" "}
                      </p>
                    </Accordion.Toggle>
                  </Card.Header>
                  <Accordion.Collapse eventKey="8">
                    <Card.Body>
                      <p>
                        ​Email kami di cs@joseaccelerated.com atau via
                        WhatsApp:+62 812-8114-5328 Gilang & WhatsApp:
                        081219135268 Fakhri
                      </p>
                    </Card.Body>
                  </Accordion.Collapse>
                </Card> */}
              </Accordion>
            </Col>
          </Row>
        </Container>
      </Container>
    </>
  )
}
